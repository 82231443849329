import { EntityManager } from "typeorm";
import { Err, ErrorCode } from "./error";
import { Account, CompanyLifeCycleEvent, Company, RequestLog } from "./model";

export async function saveLifeCycleEvent(
    account: Account,
    event: CompanyLifeCycleEvent,
    company: Company,
    log: RequestLog,
    db: EntityManager
) {
    if (!account.admin) {
        throw new Err(
            ErrorCode.INSUFFICIENT_PERMISSIONS,
            "Sie haben keine ausreichenden Berechtigungen für diese Aktion!"
        );
    }

    log.step("creating new lifecycle event");

    event.createdBy = account.name;
    event.companyId = company.id;

    await db.save(event);
    company.lifeCycleEvents = sortLifeCycleEvents([...(company.lifeCycleEvents || []), event]);
}

export async function deleteLifecycleEvent(
    account: Account,
    id: number,
    db: EntityManager,
    log: RequestLog,
    company: Company
) {
    if (!account.admin) {
        throw new Err(
            ErrorCode.INSUFFICIENT_PERMISSIONS,
            "Sie haben keine ausreichenden Berechtigungen für diese Aktion!"
        );
    }

    log.step("deleting lifecycle event");

    if (company.lifeCycleEvents.length === 1) {
        return;
    }

    const eventIndex = company.lifeCycleEvents.findIndex((e) => e.id === id);
    await db.delete(CompanyLifeCycleEvent, id);
    company.lifeCycleEvents.splice(eventIndex, 1);
}

export function sortLifeCycleEvents(events: CompanyLifeCycleEvent[]): CompanyLifeCycleEvent[] {
    return events.sort((a, b) => {
        if (a.date !== b.date) {
            return a.date < b.date ? -1 : 1;
        }

        return a.created.getTime() - b.created.getTime();
    });
}
